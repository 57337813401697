@import "../../../common.less";

.mainPage{
  box-sizing: border-box;
  padding: 20px;
  overflow: hidden;
}
.fullPage{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 20px;
}
.formContainer{
  flex: 1;
  max-width: 1200px;
  overflow: auto;
  margin-top: 20px;
}
.layerDiv{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}
.content{
  width: 100%;
  height: 100%;
  .flexColumn;
}
.footer{
  .flexRow;
  justify-content: flex-end;
  gap: 20px;
}