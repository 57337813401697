@import "../../../common.less";

.fullPage{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.layerDiv{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}
.content{
  width: 100%;
  height: 100%;
  .flexColumn;
}
.footer{
  .flexRow;
  justify-content: flex-end;
  gap: 20px;
}
.formContainerOuter{
  flex: 1;
  width: 800px;
  margin: 20px auto 20px;
  position: relative;
}
.formContainer{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  overflow: auto;
}
.formContainerHide{
  .formContainer;
  opacity: 0;
  pointer-events: none;
  z-index: 1;
}
.label{
  text-align: right;
  line-height: 32px;

  &:before{
    content: "*";
    display: inline-block;
    color: red;
  }
}
.albImgBox{
  display: inline-block;
  width: 200px;
  height: 120px;
  background-size: cover;
  margin: 10px;
  background-position: center;
  border: 1px solid #eee;
}