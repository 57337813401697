:global .ant-tabs {
  height: 100%;
}
:global .ant-tabs-content {
  height: 100%;
}
:global .ant-tabs-tabpane {
  height: 100%;
}
.maintheme {
  position: absolute;
  min-height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
}
.button {
  width: 144px;
  font-size: 16px;
  border-radius: 4px;
  height: 34px;
  border: none;
  cursor: pointer;
}
.buttonrestvalue {
  font-size: 14px;
  width: 80px;
  letter-spacing: 0;
  border-radius: 4px;
}
.buttongetvalue {
  font-size: 14px;
  color: #1585ff;
  background: #fff;
  width: 80px;
  border-radius: 4px;
}
.noSelect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
}
.btnText {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
}
.noText {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  width: 24px;
}
.hiddenBtn {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  visibility: hidden;
}
.btnDetail {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  color: #67cfff;
}
.btnAdd {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  color: #009900;
}
.btnEdit {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  color: #0008ff;
}
.btnDelete {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  color: red;
}
.containerTitle {
  font-size: 20px;
  text-indent: 20px;
  margin-bottom: 10px;
  color: #29A6FF;
  letter-spacing: 1px;
}
.containerTitle:before {
  content: " ";
  display: inline-block;
  width: 30px;
  height: 30px;
  background-image: url("../../images/cloud.png");
  background-size: 100% 100%;
  margin-right: 5px;
  margin-bottom: -5px;
}
.containerTitle:after {
  content: " ";
  display: block;
  width: 100%;
  height: 2px;
  background: linear-gradient(0deg, #29a6ff 0%, #ffffff 100%);
  background: -moz-linear-gradient(left, #29A6FF, #fff);
  background: -o-linear-gradient(left, #29A6FF, #fff);
  background: -webkit-gradient(linear, 0% 100%, 100% 0%, from(#29A6FF), to(#fff));
}
.returnPage {
  float: left;
  clear: both;
  font-size: 16px;
  padding-left: 30px;
  color: #333;
  cursor: pointer;
  margin: 5px 40px;
}
.returnPage:before {
  content: "<< ";
  display: inline;
}
.returnPage:hover {
  color: #3299ff;
  padding-left: 0;
}
.returnPage:hover:before {
  content: "<<<<< ";
  display: inline;
}
.nextPage {
  float: right;
  clear: both;
  font-size: 16px;
  padding-right: 30px;
  color: #333;
  margin: 5px 40px;
  cursor: pointer;
}
.nextPage:after {
  content: " >>";
  display: inline;
}
.nextPage:hover {
  color: #3299ff;
  padding-right: 0;
}
.nextPage:hover:after {
  content: " >>>>>";
  display: inline;
}
.toolBtn {
  min-width: 120px;
  margin: 20px 10px;
}
.jumpTitle:after {
  content: ' ';
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url("../../images/outIcon01.png");
  background-size: 100% 100%;
  vertical-align: text-bottom;
  margin-left: 5px;
}
.jumpText {
  cursor: pointer;
}
.jumpText:hover {
  color: #29A6FF;
}
.stringType {
  color: green;
}
.numberType {
  color: darkorange;
}
.booleanType {
  color: blue;
}
.nullType {
  color: magenta;
}
.keyType {
  color: red;
}
.flexRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.flexColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}
.main {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.sider {
  position: absolute;
  top: 0;
  left: 0;
  width: 230px;
  margin-top: 140px;
  margin-bottom: 30px;
  bottom: 0;
  overflow: auto;
  background: #001529;
}
.sider .sider_sub_menu {
  background: #000c17;
}
.sider_hide {
  position: absolute;
  top: 0;
  left: 0;
  width: 230px;
  margin-top: 140px;
  margin-bottom: 30px;
  bottom: 0;
  overflow: auto;
  background: #001529;
  overflow: unset;
  width: 64px;
}
.sider_hide .sider_sub_menu {
  background: #000c17;
}
.right {
  margin-left: 230px;
  height: 100%;
  min-width: 750px;
  position: relative;
  overflow-x: auto;
}
.right_hide {
  margin-left: 230px;
  height: 100%;
  min-width: 750px;
  position: relative;
  overflow-x: auto;
  z-index: 0;
  min-width: 1118px;
  margin-left: 64px;
}
.littleBox {
  color: #fff;
}
.headTitle {
  color: #fff;
  font-size: 16px;
}
.littleLogo {
  display: inline-block;
  height: 30px;
  width: 30px;
  margin: 0 auto;
  border-radius: 50%;
  border: 3px solid #FFFFFF;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
}
.logoLayout {
  position: fixed;
  top: 0;
  width: 230px;
  height: 140px;
  padding-top: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  text-align: center;
  background: #002140;
}
.logoLayout_hide {
  position: fixed;
  top: 0;
  width: 230px;
  height: 140px;
  padding-top: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  text-align: center;
  background: #002140;
  width: 64px;
}
.top {
  width: 100%;
  height: 60px;
  padding-bottom: 34px;
  line-height: 68px;
  background: #ececec;
}
.top .title {
  margin-left: 40px;
  float: left;
  font-size: 20px;
}
.top .top_icon {
  float: right;
  line-height: 68px;
}
.top .top_menu {
  float: right;
  padding-right: 20px;
  line-height: 68px;
}
.logo2 {
  display: inline-block;
  height: 70px;
  width: 70px;
  margin: 0 auto;
  border-radius: 50%;
  border: 3px solid #FFFFFF;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
}
.logo1 {
  display: none;
}
.btn1 {
  position: relative;
  bottom: 35px;
  margin: 0;
  margin-bottom: 35px;
}
.bottom {
  display: table;
  position: fixed;
  bottom: 0;
  height: 30px;
  width: 230px;
  font-size: 14px;
  padding: 0 12px;
  text-align: right;
  background: #001529;
  cursor: pointer;
}
.bottom .icon_fold {
  color: #fff;
  display: table-cell;
  vertical-align: middle;
}
.bottom .icon_fold_left {
  color: #fff;
  display: table-cell;
  vertical-align: middle;
  text-align: left;
}
.bottom .icon_fold_right {
  color: #fff;
  display: table-cell;
  vertical-align: middle;
  text-align: right;
}
.bottom_collapsed {
  display: table;
  position: fixed;
  bottom: 0;
  height: 30px;
  width: 230px;
  font-size: 14px;
  padding: 0 12px;
  text-align: right;
  background: #001529;
  cursor: pointer;
  width: 64px;
}
.bottom_collapsed .icon_fold {
  color: #fff;
  display: table-cell;
  vertical-align: middle;
}
.bottom_collapsed .icon_fold_left {
  color: #fff;
  display: table-cell;
  vertical-align: middle;
  text-align: left;
}
.bottom_collapsed .icon_fold_right {
  color: #fff;
  display: table-cell;
  vertical-align: middle;
  text-align: right;
}
.bottom_collapsed .icon_fold_left {
  text-align: center;
}
.menu_icon {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  vertical-align: middle;
  position: relative;
  top: -2px;
  display: inline-block;
}
.menu_icon_active {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -2px;
  display: none;
}
.submenu_cover {
  width: 100%;
  height: 100%;
}
.submenu_cover:hover .menu_icon,
.submenu_cover_active .menu_icon {
  display: none;
}
.submenu_cover:hover .menu_icon_active,
.submenu_cover_active .menu_icon_active {
  display: inline-block;
}
.collapsedItem {
  padding: 5px 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.collapsedItem:hover {
  color: #4fb6ff;
}
