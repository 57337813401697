/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
:global .ant-tabs {
  height: 100%;
}
:global .ant-tabs-content {
  height: 100%;
}
:global .ant-tabs-tabpane {
  height: 100%;
}
.maintheme {
  position: absolute;
  min-height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
}
.button {
  width: 144px;
  font-size: 16px;
  border-radius: 4px;
  height: 34px;
  border: none;
  cursor: pointer;
}
.buttonrestvalue {
  font-size: 14px;
  width: 80px;
  letter-spacing: 0;
  border-radius: 4px;
}
.buttongetvalue {
  font-size: 14px;
  color: #1585ff;
  background: #fff;
  width: 80px;
  border-radius: 4px;
}
.noSelect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
}
.btnText {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
}
.noText {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  width: 24px;
}
.hiddenBtn {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  visibility: hidden;
}
.btnDetail {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  color: #67cfff;
}
.btnAdd {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  color: #009900;
}
.btnEdit {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  color: #0008ff;
}
.btnDelete {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  color: red;
}
.containerTitle {
  font-size: 20px;
  text-indent: 20px;
  margin-bottom: 10px;
  color: #29A6FF;
  letter-spacing: 1px;
}
.containerTitle:before {
  content: " ";
  display: inline-block;
  width: 30px;
  height: 30px;
  background-image: url("../../images/cloud.png");
  background-size: 100% 100%;
  margin-right: 5px;
  margin-bottom: -5px;
}
.containerTitle:after {
  content: " ";
  display: block;
  width: 100%;
  height: 2px;
  background: linear-gradient(0deg, #29a6ff 0%, #ffffff 100%);
  background: -moz-linear-gradient(left, #29A6FF, #fff);
  background: -o-linear-gradient(left, #29A6FF, #fff);
  background: -webkit-gradient(linear, 0% 100%, 100% 0%, from(#29A6FF), to(#fff));
}
.returnPage {
  float: left;
  clear: both;
  font-size: 16px;
  padding-left: 30px;
  color: #333;
  cursor: pointer;
  margin: 5px 40px;
}
.returnPage:before {
  content: "<< ";
  display: inline;
}
.returnPage:hover {
  color: #3299ff;
  padding-left: 0;
}
.returnPage:hover:before {
  content: "<<<<< ";
  display: inline;
}
.nextPage {
  float: right;
  clear: both;
  font-size: 16px;
  padding-right: 30px;
  color: #333;
  margin: 5px 40px;
  cursor: pointer;
}
.nextPage:after {
  content: " >>";
  display: inline;
}
.nextPage:hover {
  color: #3299ff;
  padding-right: 0;
}
.nextPage:hover:after {
  content: " >>>>>";
  display: inline;
}
.toolBtn {
  min-width: 120px;
  margin: 20px 10px;
}
.jumpTitle:after {
  content: ' ';
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url("../../images/outIcon01.png");
  background-size: 100% 100%;
  vertical-align: text-bottom;
  margin-left: 5px;
}
.jumpText {
  cursor: pointer;
}
.jumpText:hover {
  color: #29A6FF;
}
.stringType {
  color: green;
}
.numberType {
  color: darkorange;
}
.booleanType {
  color: blue;
}
.nullType {
  color: magenta;
}
.keyType {
  color: red;
}
.flexRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.flexColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}
.colorStyle {
  color: #3e3e3e;
  background-color: #f5f5f5;
}
.refreshBtn {
  display: inline-block;
  margin-bottom: 30px;
}
.refreshBtn:hover {
  color: #2f86fc;
}
.closeBtn {
  display: inline-block;
  margin-left: 30px;
}
.closeBtnActive {
  display: inline-block;
  margin-left: 30px;
  color: #2f86fc;
}
.tabSelector {
  top: -3px;
  position: relative;
  color: #3e3e3e;
  background-color: #f5f5f5;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 0px;
  border: 1px solid rgba(128, 128, 128, 0.3);
  border-bottom: none;
}
.tabSelector .tabSelector_label {
  color: #3e3e3e;
  background-color: #f5f5f5;
  width: 200px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  height: 31px;
  line-height: 31px;
  border-radius: 5px;
}
.tabSelector .tabSelector_content {
  z-index: 10;
  position: absolute;
  right: -1px;
  width: 202px;
  transition: 1s linear;
  -webkit-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: #3e3e3e;
  background-color: #f5f5f5;
  border-radius: 0px;
  border: 1px solid rgba(128, 128, 128, 0.3);
  border-top: none;
}
.tabSelector_content_hide {
  height: 0;
  width: 0;
  opacity: 0;
}
.tabSelector_showItem {
  position: absolute;
}
.tabItem_close {
  position: absolute;
  width: 1px;
  height: 15px;
  right: 20px;
  top: 10px;
  background-color: #333;
}
.tabItem {
  display: table;
  height: 32px;
  line-height: 32px;
  width: 100%;
  height: 38px;
}
.tabItem_active_bg {
  background-color: #eee;
}
.tabItem .tabItem_title {
  display: table-cell;
  vertical-align: middle;
  background-color: transparent;
}
.tabItem .tabItem_info {
  display: table-cell;
  width: 40px;
  vertical-align: middle;
  text-align: center;
  background-color: transparent;
}
.tabItem .tabItem_info .tabItem_info_dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #1585ff;
}
.tabItem .tabItem_icon {
  display: table-cell;
  position: relative;
  width: 20%;
  vertical-align: middle;
  background-color: transparent;
}
.tabItem .tabItem_icon .tabItem_close_right {
  position: absolute;
  width: 1px;
  height: 15px;
  right: 20px;
  top: 10px;
  background-color: #333;
  transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
}
.tabItem .tabItem_icon .tabItem_close_left {
  position: absolute;
  width: 1px;
  height: 15px;
  right: 20px;
  top: 10px;
  background-color: #333;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}
.tabItem_active {
  color: #1585ff;
}
.tabList_header_btn {
  width: 100%;
  display: inline-block;
  height: 38px;
  line-height: 38px;
  text-align: center;
  border-bottom: 1px solid black;
}
