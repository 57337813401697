.mainPage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.conversationBox {
  width: 300px;
  height: 100%;
  border-right: 1px solid #eee;
}

.chatBox {
  flex: 1;
  height: 100%;
}
.listEmptyView{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  height: 100%;
}