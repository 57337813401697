@import "../../common.less";
.main2{
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 20px;
  z-index: 130;
  overflow: auto;
  background-color: #fff;
}
.main{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.main_overlay{
  .main;
  top: 30px;
  z-index: 10;
}
.mainindex{
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  bottom: 0;
}
.content{
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0;
  overflow: auto;
  .webkit_scrollbar();
}
.tab1{
  position: relative;
  z-index: 100;
  bottom: 1px;
  width: 100px;
  line-height: 32px;
  font-size: 16px;
  height: 32px;
  text-align: center;
  // padding:0 32px;
  box-sizing: border-box;
  color:#00A0E9;
  cursor: pointer;
  border-radius:2px;
  transition: color .3s cubic-bezier(.645,.045,.355,1);
}
.tab1:hover{
  background: #1f2637;
  color: #8bf1fc;
}
.tab1-active {
  background: hsla(0,0%,100%,.15);
}